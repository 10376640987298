import { defineComponent, ref, reactive, toRefs, onMounted, watch } from "vue";
import { ArrowRight } from "@element-plus/icons-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { imgUrl } from "@/utils/urlManage";
import { getContentTemplateDetail } from "@/http/home";
export default defineComponent({
  props: {
    falg: {
      default: false
    },
    productDeatil: {
      default: []
    },
    childCode: {
      default: ""
    }
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const carouselRef = ref();
    const data = reactive({
      // 产品数据
      // productDeatil: <ProductType.ProductType[]>[],
      flag: props.falg,
      onlyDetail: {},
      num: 0
    });
    const methods = {
      /**
       * 点击切换轮播图
       */
      changeCarousel(i) {
        var _carouselRef$value;

        (_carouselRef$value = carouselRef.value) === null || _carouselRef$value === void 0 ? void 0 : _carouselRef$value.setActiveItem(i);
        data.num = i;
      },

      //获取右侧数据
      getProductDeailMethod(ico) {
        data.flag = false;
        methods.lookDetail(ico.code);
      },

      changeNum(i) {
        data.num = i;
      },

      /**
       * 查看详情
       */
      lookDetail(str) {
        data.flag = true;
        getContentTemplateDetail({
          code: str,
          partCode: props.childCode,
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data == undefined) return;
            data.onlyDetail = res.data.data;
          } else {
            ElMessage.error(res.data.message);
          }
        });
      }

    };
    onMounted(() => {
      if (data.flag) {
        methods.lookDetail(props.productDeatil.code);
      }
    }); // 监听路由变化[id]

    watch(() => props.falg, (newValue, oldValue) => {
      data.flag = newValue;
    });
    return { ...toRefs(data),
      ArrowRight,
      ...methods,
      imgUrl,
      carouselRef,
      route
    };
  }

});