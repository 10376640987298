import { defineComponent, reactive, toRefs, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getContentTemplateTypeList, getPart, getContentTemplateDetail } from "@/http/home";
import { ArrowRight } from "@element-plus/icons-vue";
import { imgUrl } from "@/utils/urlManage";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
import { ElMessage } from "element-plus";
import rightHtml from "./components/rightHtml.vue";
import { fileExt } from "@/utils/utils";
export default defineComponent({
  components: {
    rightHtml
  },

  setup(props) {
    const carouselKey = ref(0);
    const divDomList = ref([]);

    const getCarou = el => {
      if (el) {
        divDomList.value.push(el);
      }
    };

    const route = useRoute();
    const router = useRouter();

    const _this = reactive({
      falg: false,
      title: "",
      subtitle: "",
      pathList: [],
      frameUrl: "",
      defaultProps: {
        children: "children",
        label: "name"
      },
      defaultKeys: [],
      defaultNode: "",
      treeList: [],
      productDeatil: [],
      pathCode: "",
      childCode: "",
      onlyDetail: [],
      autoplay: true,
      cutTime: 3000
    });

    const trees = ref();
    const methods = {
      //重置面包屑
      resertPath() {
        if (route.query.partCode) {
          _this.pathList = [{
            title: _this.title,
            goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${""}`
          }];
        } else {
          _this.pathList = [];
        }
      },

      /**
       * 点击头部面包屑跳转
       * @param title
       */
      changeBread(title) {
        if (title.goPath == route.fullPath) {
          location.reload();
        } else {
          router.push(title.goPath);
        }
      },

      //获取数据
      async getData() {
        const res = await getContentTemplateTypeList({
          partCode: route.query.partCode,
          orgCode: sessionStorage.getItem("orgCode") || "",
          listCode: 3
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          // 处理后的数据
          let result = [];
          await res.data.data.forEach(({
            children,
            ...e
          }, index) => {
            // 复制第一层数据，并初始化 children
            result.push({ ...e,
              children: []
            }); // 处理第二层数据

            if (children) {
              // 处理 subClass
              if (children.subClass) {
                const subChild = children.subClass.map(({
                  details,
                  ...rest
                }) => {
                  // 处理第三层数据
                  const thirdChild = details ? [...details] : [];
                  return { ...rest,
                    children: thirdChild
                  };
                });
                result[index].children = subChild;
              } // 处理 details


              if (children.details) {
                children.details.forEach(e => {
                  result[index].children.push(e);
                }); // result[index].children = [...children.details];
              }
            }
          });
          _this.treeList = result;
          console.log(result, "resultresult");

          if (route.query.code) {
            await methods.changMeau();
            await methods.changeKeys(route.query.code);
          } else {
            await methods.changeMeauOne(result[0]);
            await methods.changeKeys(result[0].code);
          }
        } else {
          ElMessage.error(res.data.message);
        }
      },

      async getDatas() {
        const twoRes = await getPart({
          code: route.query.partCode,
          orgCode: sessionStorage.getItem("orgCode") || ""
        });
        _this.title = twoRes.data.data.name;
        _this.subtitle = twoRes.data.data.subtitle;
        _this.pathCode = twoRes.data.data.code;
        methods.resertPath();
      },

      /**
       *
       *  节点点击事件
       * @param node 当前点击的节点
       */
      handleNodeClick(node, tree) {
        _this.onlyDetail = {};
        _this.productDeatil = [];

        if (tree.level == 1) {
          methods.changeMeauOne(node);
        } else if (tree.level == 2) {
          if (node.children) {
            methods.changeMeauTwo(node, tree.parent.data);
          } else {
            methods.changeMeauProductstwo(node, tree.parent.data);
          }
        } else {
          methods.changeMeauThreeTwo(node, tree.parent.parent.data, tree.parent.data);
        }
      },

      /**+
       * 改变树的展开值，并合上其他节点
       * @param id 需要展开的节点的id
       */
      async changeKeys(code) {
        var _trees$value$store;

        // if (id != _this.defaultKeys[0]) {
        //  保证树形结构只有一个项目展开，先合上全部的节点
        await nextTick();
        let obj = (_trees$value$store = trees.value.store) === null || _trees$value$store === void 0 ? void 0 : _trees$value$store.nodesMap;

        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            element.expanded = false;
          }
        } // 再重新赋值打开的节点值


        _this.defaultKeys = [code];
        _this.defaultNode = code;
        trees.value.setCurrentKey(code); // }
      },

      /**
       * 点击一级修改右侧数据
       * @param str
       */
      changeMeauOne(str) {
        _this.productDeatil = []; //表头更改

        methods.resertPath();

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.falg = false;
        _this.childCode = str.code;
        let typeFlag = false;
        str.children.forEach(ele => {
          if (ele.children) {
            typeFlag = true;
          }
        });

        if (typeFlag) {
          _this.productDeatil = str.children;
        } else {
          _this.productDeatil = [{
            name: str.name,
            children: str.children
          }];
        } // if (str.type == "firstClass") {
        // _this.productDeatil = [{ name: str.name, children: str.children }];
        // } else {


        console.log(_this.productDeatil, " _this.productDeatil _this.productDeatil"); // }

        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      //点击列表切换的不调取详情接口
      changeMeauProductstwo(str, oneStr) {
        _this.productDeatil = [];
        methods.resertPath();

        _this.pathList.push({
          title: oneStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${oneStr.code}`
        });

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.childCode = oneStr.code; // router.push(`/product/detail/${str.code}`);

        _this.falg = true;
        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      /**
       *
       * @param str 二级详情数据
       * @param name 一级的name
       */
      changeMeauProducts(str, oneStr) {
        _this.productDeatil = [];
        methods.resertPath();

        _this.pathList.push({
          title: oneStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${oneStr.code}`
        });

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.childCode = oneStr.code; // router.push(`/product/detail/${str.code}`);

        _this.falg = true;
        methods.getProductDeailMethod(str);
        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      /**
       * 点击二级修改右侧数据
       * @param str
       */
      changeMeauTwo(str, oneStr) {
        _this.productDeatil = [];

        _this.productDeatil.push(str);

        methods.resertPath();

        _this.pathList.push({
          title: oneStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${oneStr.code}`
        });

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.childCode = oneStr.code;
        _this.falg = false;
        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      //点击列表切换的不调取详情接口
      changeMeauThreeTwo(str, oneStr, twoStr) {
        _this.productDeatil = [];
        methods.resertPath();

        _this.pathList.push({
          title: oneStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${oneStr.code}`
        });

        _this.pathList.push({
          title: twoStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${twoStr.code}`
        });

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.childCode = oneStr.code;
        _this.falg = true;
        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      /**
       * 点击三级修改右侧数据
       * @param str
       */
      changeMeauThree(str, oneStr, twoStr) {
        _this.productDeatil = [];
        methods.resertPath();

        _this.pathList.push({
          title: oneStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${oneStr.code}`
        });

        _this.pathList.push({
          title: twoStr.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${twoStr.code}`
        });

        _this.pathList.push({
          title: str.name,
          goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`
        });

        _this.childCode = oneStr.code;
        _this.falg = true;
        methods.getProductDeailMethod(str);
        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${str.code}`);
      },

      //进入页面判断
      async changMeau() {
        _this.treeList.forEach((item, i) => {
          if (item.code == route.query.code) {
            methods.changeMeauOne(item);
          } else {
            if (item.children) {
              var _item$children;

              (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.forEach(ico => {
                if (ico.code == route.query.code) {
                  if (ico.children) {
                    methods.changeMeauTwo(ico, item);
                  } else {
                    methods.changeMeauProducts(ico, item);
                  }
                } else {
                  var _ico$children;

                  (_ico$children = ico.children) === null || _ico$children === void 0 ? void 0 : _ico$children.forEach(model => {
                    if (model.code == route.query.code) {
                      methods.changeMeauThree(model, item, ico);
                    }
                  });
                }
              });
            } else {
              var _item$children2;

              (_item$children2 = item.children) === null || _item$children2 === void 0 ? void 0 : _item$children2.forEach(model => {
                if (model.code == route.query.code) {
                  methods.changeMeauProducts(model, item);
                }
              });
            }
          }
        });
      },

      //获取右侧数据
      getProductDeailMethod(ico) {
        methods.changeKeys(ico.code);
        _this.falg = false;
        methods.lookDetail(ico.code);
      },

      getProductDeail(item, ico) {
        if (item.code != ico.code) {
          _this.pathList.push({
            title: item.name,
            goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${item.code}`
          });

          _this.pathList.push({
            title: ico.name,
            goPath: `/columnDisplay?partCode=${route.query.partCode}&code=${ico.code}`
          });
        }

        router.push(`/columnDisplay?partCode=${route.query.partCode}&code=${ico.code}`);
      },

      /**
       * 查看详情
       */
      lookDetail(str) {
        _this.falg = true;
        _this.onlyDetail = {};
        getContentTemplateDetail({
          code: str,
          partCode: route.query.partCode,
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data == undefined) return;
            _this.onlyDetail = res.data.data;
            carouselKey.value += 1;
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      /**
       * 获取走马灯的索引
       * @param nowIndex 当前走马灯元素的索引值
       */
      getIndex(nowIndex) {
        // 只对视频元素做操作
        let type = fileExt(_this.onlyDetail.rollFiles[nowIndex].fileUrl); // return
        // 只对视频元素做操作

        if (type != "png" && type != "jpg" && type != "gif" && type != "PNG" && type != "JPG" && type != "GIF") {
          let myVideo = divDomList.value[nowIndex]; //用户可能在上个视频未播放完毕就切换

          myVideo.currentTime = 0; // 拿到视频的时间，并加上延迟10ms，避免切换的太突兀

          _this.cutTime = myVideo.duration * 1000 + 10; // 监听播放

          myVideo.addEventListener("play", () => {
            _this.autoplay = false;
          }); //监听暂停

          myVideo.addEventListener("pause", () => {
            _this.autoplay = true;
          }); //监听播放完毕

          myVideo.addEventListener("ended", () => {
            //时间归零
            _this.autoplay = true;
            myVideo.currentTime = 0;
            _this.cutTime = 3000;
          });
        }
      },

      // 下载文件
      downloadDoc(row) {
        let exc = imgUrl(row.url).substring(imgUrl(row.url).lastIndexOf("."));
        let fileName = row.name ? row.name : row.seriesNo;

        if (exc == ".pdf") {
          window.open(imgUrl(row.url));
        } else {
          let link = document.createElement("a");
          link.style.display = "none"; // link.href = url;

          link.href = imgUrl(row.url);
          link.setAttribute("download", fileName + exc);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

    };
    onMounted(() => {
      methods.getData();
      methods.getDatas();
    });
    watch(() => route.query.code, async (newValue, oldValue) => {
      if (route.path == "/columnDisplay") {
        if (newValue != "") {
          await methods.changMeau();
          await methods.changeKeys(route.query.code); // _this.defaultKeys = [route.query.code as string];
          // _this.defaultNode = route.query.code as string;
        } else {
          await methods.changeMeauOne(_this.treeList[0]);
          await methods.changeKeys(_this.treeList[0].code);
        }
      }
    });
    watch(() => route.query.partCode, (newValue, oldValue) => {
      if (route.path == "/columnDisplay") {
        if (newValue != oldValue) {
          _this.treeList = [];
          _this.productDeatil = [];
          _this.onlyDetail = {};
          methods.getData();
          methods.getDatas();
        }
      }
    });
    return { ...toRefs(_this),
      ...methods,
      trees,
      ArrowRight,
      imgUrl,
      route,
      getCarou,
      divDomList,
      fileExt,
      carouselKey
    };
  }

});